import styled, { css } from 'styled-components';
import { ReactNode, useEffect, useRef } from 'react';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { SLOTS_SCROLL_CARD_COUNT } from '@modules/slots/constants/Slots.constants';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { useChatVisible } from '@modules/chat/store/selectors/useChatVisible';
import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';
import { ScrollMenu } from '@common/components/layout/ScrollCards/ScrollMenu/ScrollMenu';
import { StyledControlArrow } from '@common/components/layout/ScrollCards/ScrollCards.styled';
import { MenuProps } from '@common/components/layout/ScrollCards/ScrollMenu/types';

const SCROLL_MENU_PROPS: MenuProps = {
	alignCenter: false,
	clickWhenDrag: false,
	wheel: false,
	dragging: true,
	hideArrows: false,
	hideSingleArrow: true,
	scrollToSelected: false,
	translate: 0,
	transition: 0.2,
	itemClassActive: '',
	scrollBy: 1,
};

export type ScrollCardsList = Array<{ name?: string; component: JSX.Element }>;

export const Menu = (
	list: ScrollCardsList,
	loaded?: boolean,
): MenuProps['data'] => {
	if (list?.length) {
		return list.map((el) => {
			return el.component;
		});
	}

	if (loaded) {
		return [];
	}

	return [...Array(SLOTS_SCROLL_CARD_COUNT)].map((value, index) => {
		return (
			<RectangleLoader
				key={index}
				width={30}
				height={30}
				id={`loader-scroll-cards-${index}`}
			/>
		);
	});
};

const useScrollMenuRefHandling = ({ ref }: { ref: ScrollMenu }) => {
	const isChatVisible = useChatVisible();
	const isChatVisiblePrev = usePrevious(isChatVisible);

	useEffect(() => {
		if (isChatVisible !== isChatVisiblePrev) {
			setTimeout(() => {
				if (typeof ref?.resizeHandler === 'function') {
					ref.resizeHandler();
				}
			}, 500);
		}
	}, [isChatVisible]);
};

export const ScrollCards = ({
	list,
	menuProps,
	loaded,
	noDataContent,
}: {
	list: ScrollCardsList;
	menuProps?: MenuProps;
	loaded?: boolean;
	noDataContent?: ReactNode;
}) => {
	const ScrollMenuRef = useRef(null);
	useScrollMenuRefHandling({ ref: ScrollMenuRef?.current });

	const data = Menu(list, loaded);

	if (!data?.length) {
		if (noDataContent) {
			return <>{noDataContent}</>;
		}

		return (
			<>
				{trans({
					label: 'Missing content',
				})}
			</>
		);
	}

	return (
		<StyledScrollCardsWrapper>
			<ScrollMenu
				ref={ScrollMenuRef}
				arrowLeft={<StyledControlArrow $type={'prev'} />}
				arrowRight={<StyledControlArrow $type={'next'} />}
				data={data}
				onSelect={() => ({})}
				{...SCROLL_MENU_PROPS}
				{...menuProps}
			/>
		</StyledScrollCardsWrapper>
	);
};

export const CSSClassNameScrollCardsWithTitle = 'has-view-all-btn';

export const StyledScrollCardsWrapper = styled.div`
	width: 100%;

	.menu-wrapper {
		width: 100%;
	}
	.menu-wrapper--inner {
		display: flex;

		svg[id*='loader-scroll-cards'] {
			height: 200px;
			margin-right: 16px;
			max-width: unset;

			${chatViewport(
				(viewportPadding) => css`
					@media (max-width: ${(props) =>
							calcStyle({
								a: props.theme.media.maxWidthTablet,
								b: viewportPadding,
							})}) {
						height: 180px;
					}

					@media (max-width: ${(props) =>
							calcStyle({
								a: props.theme.media.maxWidthSmallTablet,
								b: viewportPadding,
							})}) {
						height: 140px;
					}

					@media (max-width: ${(props) =>
							calcStyle({
								a: props.theme.media.maxWidthMobile,
								b: viewportPadding,
							})}) {
						height: 130px;
					}
				`,
			)}
		}
	}

	.horizontal-menu {
		position: relative;
	}

	.scroll-menu-arrow {
		align-items: center;
		background: ${(props) => props.theme.colors.darkActive};
		border-radius: 50%;
		box-shadow: 0 0 17px 0 #000000;
		cursor: pointer;
		display: flex;
		height: 30px;
		position: absolute;
		transition: all 0.2s ease-in-out;
		width: 30px;
		z-index: 1;

		&:hover {
			transform: scale(1.2);
			&:last-child {
				transform: scale(1.2);
			}
		}
	}

	.scroll-menu-arrow {
		background-color: var(--color-dark-500);
		right: 0;
		top: -72px;

		@media (min-width: ${(props) => props.theme.media_v2.min_tablet_large}) {
			top: -71px;
		}

		@media (min-width: ${(props) => props.theme.media_v2.min_desktop_medium}) {
			top: -70px;
		}

		${StyledControlArrow} {
			&:before {
				border-color: var(--color-dark-50) !important;
			}
		}

		&.left {
			right: 40px;
		}

		&.right {
			right: 0;
		}

		.${CSSClassNameScrollCardsWithTitle} &.left {
			right: 150px;
		}

		.${CSSClassNameScrollCardsWithTitle} &.right {
			right: 110px;
		}

		&--disabled {
			background-color: var(--color-dark-700);
			pointer-events: none;

			${StyledControlArrow} {
				&:before {
					border-color: var(--color-dark-300) !important;
				}
			}
		}
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		.scroll-menu-arrow {
			top: -65px;
		}
	}
`;
